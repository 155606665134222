<template>
    <modal ref="modalEditarLeecheros" :titulo="`Editar ${$config.vendedor}`" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="asignarLeecheros">
        <cargando v-if="cargando" />
        <div class="row mx-3 justify-center mt-3">
            <div class="col-5">
                <p class="text-general pl-3 f-12">
                    {{$config.vendedor}}
                </p>
                <el-select
                v-model="leechero"
                placeholder="Seleccionar"
                filterable remote
                :remote-method="filtrarLeecheros"
                :loading="loading"
                class="w-100 mb-3" size="small"
                :clearable="true"
                @clear="limpiarLeechero"
                @change="seleccionarLeechero"
                >
                    <el-option
                    v-for="item in leecheros"
                    :key="item.id"
                    :label="item.leechero +' - '+ item.cedi"
                    :value="item.id"
                    />
                </el-select>
                <div class="row mx-0 py-4" />
                <div v-for="(vin,index) in leecherosSeleccionados" :key="index" class="row mx-0 align-items-center mb-3">
                    <img :src="vin.logo" class="obj-cover br-4" width="46" height="46" />
                    <div class="col px-2">
                        <p class="text-general f-15 f-500">
                            {{ vin.leechero }}
                        </p>
                        <p class="text-general f-14">
                            {{ vin.cedi }}
                        </p>
                    </div>
                    <i class="icon-cancel f-20 text-general cr-pointer" @click="eliminarLeechero(vin.id, 'vinculado')" />
                </div>
            </div>
            <div class="col-5">
                <p class="text-general pl-3 f-12">
                    {{$config.vendedor}} de desvinculación
                </p>
                <el-select v-model="leecheroDesv"
                           filterable
                           remote
                           :remote-method="filtrarLeecherosDesv"
                           :loading="loading"
                           placeholder="Seleccionar"
                           class="w-100 mb-3"
                           size="small"
                           :clearable="true"
                           @clear="limpiarLeecheroDesv"
                           @change="seleccionarLeecheroDesv"
                >
                    <el-option
                    v-for="item in leecherosDesv"
                    :key="item.id"
                    :label="item.leechero +' - '+ item.cedi"
                    :value="item.id"
                    />
                </el-select>
                <div class="row mx-0 py-4" />
                <div v-for="(desv,index) in leecherosSeleccionadosDesv" :key="index" class="row mx-0 align-items-center mb-3">
                    <img :src="desv.logo" class="obj-cover br-4" width="46" height="46" />
                    <div class="col px-2">
                        <p class="text-general f-15 f-500">
                            {{ desv.leechero }}
                        </p>
                        <p class="text-general f-14">
                            {{ desv.cedi }}
                        </p>
                    </div>
                    <i class="icon-cancel f-20 text-general cr-pointer" @click="eliminarLeechero(desv.id, 'desvinculado')" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/nomina/nomina'

export default {
    emits: ['actualizar'],
    data(){
        return {
            loading: false,
            cargando: false,
            leecheros: [],
            leecherosDesv: [],
            leecherosSeleccionados: [],
            leecherosSeleccionadosDesv: [],
            leechero: '',
            leecheroDesv: '',
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    methods: {
        async getLeecherosEmpresa(){
            try {
                const {data} = await Service.getLeecherosEmpresa(this.id_convenio)
                this.leecherosSeleccionados = data.leecherosVinculados;
                this.leecherosSeleccionadosDesv = data.leecherosDesvinculados;
            } catch(e){
                this.error_catch(e)
            }
        },
        async asignarLeecheros(){
            try {
                this.cargando = true;
                const form = {
                    leecherosVincular: this.leecherosSeleccionados,
                    leecherosDesvincular: this.leecherosSeleccionadosDesv
                };
                const {data} = await Service.asignarLeecherosEmpresa(this.id_convenio, form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar', this.leecherosSeleccionados.length);
                this.limpiar();
                this.$refs.modalEditarLeecheros.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },
        async filtrarLeecheros(query){
            try {
                if (query.length < 3) return this.leecheros = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getLeecheros(params)
                this.leecheros = data.leecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async filtrarLeecherosDesv(query){
            try {
                if (query.length < 3) return this.leecherosDesv = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getLeecheros(params)
                this.leecherosDesv = data.leecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionarLeechero(){
            if (!this.leechero) return
            let existe = this.leecherosSeleccionados.filter((item) => item.id === this.leechero);
            let existe2 = this.leecherosSeleccionadosDesv.filter((item) => item.id === this.leechero);
            if (existe.length > 0 || existe2.length > 0){
                this.leechero = '';
                this.leecheros = [];
                return;
            }
            let dataFilter = this.leecheros.find((item) => item.id === this.leechero);
            this.leecherosSeleccionados.push(dataFilter);
            this.leechero = '';
            this.leecheros = [];
        },
        seleccionarLeecheroDesv(){
            if (!this.leecheroDesv) return
            let existe = this.leecherosSeleccionados.filter((item) => item.id === this.leecheroDesv);
            let existe2 = this.leecherosSeleccionadosDesv.filter((item) => item.id === this.leecheroDesv);
            if (existe.length > 0 || existe2.length > 0){
                this.leecheroDesv = '';
                this.leecherosDesv = [];
                return;
            }
            let dataFilter = this.leecherosDesv.find((item) => item.id === this.leecheroDesv);
            this.leecherosSeleccionadosDesv.push(dataFilter);
            this.leecheroDesv = '';
            this.leecherosDesv = [];
        },
        async eliminarLeecheroSeleccionado(idLeechero){
            let index = this.leecherosSeleccionados.findIndex(e => e.id === idLeechero)
            this.leecherosSeleccionados.splice(index, 1);
        },
        eliminarLeecheroSeleccionadoDesv(idLeechero){
            let index = this.leecherosSeleccionadosDesv.findIndex(e => e.id === idLeechero)
            this.leecherosSeleccionadosDesv.splice(index, 1);
        },
        async eliminarLeechero(idLeechero, bandera){
            try {
                if(bandera === 'vinculado'){
                    if(this.leecherosSeleccionados.length < 2){
                        return this.notificacion('¡No es posible!','Vendedores no puede quedar vacío','warning');
                    }
                } else {
                    if(this.leecherosSeleccionadosDesv.length < 2){
                        return this.notificacion('¡No es posible!','Vendedores desvinculación no puede quedar vacío','warning');
                    }
                }
                const {data} = await Service.deleteLeecheroEmpresa(this.id_convenio, idLeechero, bandera == 'vinculado' ? 1 : 2)
                if(data.tipo == "warning"){
                    return this.notificacion('Mensaje', data.mensaje, data.tipo);
                }
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if (bandera === "vinculado"){
                    this.eliminarLeecheroSeleccionado(idLeechero);
                } else {
                    this.eliminarLeecheroSeleccionadoDesv(idLeechero);
                }
                this.$emit('actualizar', this.leecherosSeleccionados.length);
            } catch(e){
                this.error_catch(e)
            }
        },
        limpiarLeechero(){
            this.leechero = '';
        },
        limpiarLeecheroDesv(){
            this.leecheroDesv = '';
        },
        limpiar(){
            this.loading = false;
            this.cargando = false;
            this.leecheros = [];
            this.leecherosDesv = [];
            this.leecherosSeleccionados = [];
            this.leecherosSeleccionadosDesv = [];
            this.leechero = '';
            this.leecheroDesv = '';
        },
        toggle(){
            this.getLeecherosEmpresa();
            this.$refs.modalEditarLeecheros.toggle();
        }
    }
}
</script>
